<template>
	<!-- 外购件情况 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title"> 外购件 </div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="addOrUpdateHandle()">添加</el-button>
					<el-button v-preventReClick :disabled="disabledBatchDelete" class="btn" type="primary" size="small"
						@click="deleteBatch()">删除</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="uploadHandle()">导入</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="downloadHandle()">导出</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="downloadTemplate()">模板下载</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="到货标识" class="selFormItem">
							<el-select class="selMajorInput" v-model="form.arrivalFlg" placeholder="请选择" clearable>
								<el-option v-for="item in arrivalFlgList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							v-if="showSearch">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 530px;" body-style="height:490px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="470" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								ref="dataListTable" class="dataListTable" :data="dataList" header-align="center"
								style="width: 100%; margin-bottom: 20px;" header-cell-class-name="dataListTHeader" border
								@selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }">
								<el-table-column type="selection" width="60" align="center"></el-table-column>
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="showFlg" label="显示" align="left" width="60">
									<template slot-scope="scope">
										<el-checkbox v-model="scope.row.showFlg" true-label="1" :disabled="true"
											false-label="0"></el-checkbox>
									</template>
								</el-table-column>
								<el-table-column prop="deviceName" label="设备名称" width="140"
									align="center"></el-table-column>
								<el-table-column prop="technicalFactory" label="技术协议要求厂家" width="150"
									align="center"></el-table-column>
								<el-table-column prop="quantity" label="数量" width="80" align="center"></el-table-column>
								<el-table-column prop="contractSignFlg" label="合同签订情况" width="120" align="center">
								</el-table-column>
								<el-table-column prop="deviceFactory" label="实际订货厂家" width="150"
									align="center"></el-table-column>
								<el-table-column prop="arrivalDatePlan" label="计划到货" width="150"
									align="center"></el-table-column>
								<el-table-column prop="arrivalDateFact" label="实际到货" width="150"
									align="center"></el-table-column>
								<el-table-column prop="remark" label="备注" width="150" align="center"></el-table-column>
								<el-table-column label="操作" width="100" align="center"
									v-if="showDelete || showEdit || showLook">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.recNo)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)" v-if="showDelete">删除</el-button>
										<!--                  <el-button v-preventReClick type="danger" size="small" @click="showLookHandle(scope.row.recNo)" v-if="showLook">报告显示</el-button>-->
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<meeting-list-look v-if="showLook" ref="meetingLook" @refreshDataList="getDataList"></meeting-list-look>
		<excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="dataList"></excel-upload>
	</div>
</template>
<script>
import AddOrUpdate from './purchaseDevice-add-or-update'
import ExcelUpload from './purchaseDevice-excel-upload'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			partNo: '',
			showSearch: false,
			showAdd: false,
			showDelete: false,
			showEdit: false,
			showLook: false,
			excelUploadVisible: false,
			pageSpan: 12,
			addOrUpdateVisible: false,
			form: {
				arrivalFlg: "",
			},
			tableSelVal: [],
			disabledBatchDelete: true,
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			arrivalFlgList: [
				{
					label: '未到货',
					value: '0'
				}, {
					label: '已到货',
					value: '1'
				}
			],
			//总页数
			totalPage: 0,
			workNo: $common.getItem("workNo"),
			subjectNo: $common.getItem("subjectNo"),
			dataList: [],
		};
	},
	components: {
		AddOrUpdate,
		ExcelUpload
	},
	mounted() {
		this.showSearch = $common.isAuth('shipProgress:search');
		this.showAdd = $common.isAuth('shipProgress:add');
		this.showDelete = $common.isAuth('shipProgress:delete');
		this.showEdit = $common.isAuth('shipProgress:edit');
		this.showLook = $common.isAuth('problem:look');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		if (this.showSearch) {
			this.getDataList();
		}
		this.pageIndex2 = 1;
	},
	methods: {
		uploadHandle() {
			this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init()
			})
		},
		downloadHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/purchasedeviceinfo/download",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					arrivalFlg: this.form.arrivalFlg,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '外购件进度表.xlsx');
					}
					// this.global.downLoadFile(data.body,'外购件进度表.xlsx');
					// window.open(data.body)
					// this.dataList = data.body.records;
					// this.totalPage = data.body.totalCount;
				} else {
				}
			})
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=8",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						// window.open(data.body);
						if (this.$store.state.showTestFlag) {
							window.open(data.body)
						} else {
							this.global.downLoadFile(data.body, '外购件进度表模板.xlsx');
						}
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.recNo);
				this.$http({
					url: this.$store.state.httpUrl + "/business/purchasedeviceinfo/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			});
		},
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/purchasedeviceinfo/list",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					arrivalFlg: this.form.arrivalFlg,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				} else {
					this.dataList = []
					this.totalPage = 0
					this.pageIndex2 = 1
				}
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return {
				height: height + 'px'
			}
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		}, showLookHandle(id) {
			this.showLook = true
			this.$nextTick(() => {
				this.$refs.meetingLook.init(id)
			})
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/purchasedeviceinfo/delete/",
					method: 'post',
					data: this.$http.adornData([id], false)
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			});
		},
		resetFormData() {
			this.partNo = "";
			this.pageIndex = 1;
			this.getDataList();
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList();
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recNo);
			if (checkIdList.includes(row.recNo)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selMajorInput .el-input__inner) {
	width: 110px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

</style>
