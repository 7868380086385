<template>
	<el-dialog :title="!dataForm.recNo ? '新增外购件情况' : '修改外购件情况'" :close-on-click-modal="false" :visible.sync="visible"
		width="650px">
		<el-form ref="dataForm" :model="dataForm" inline label-width="140px" label-position="right" class="border-form">
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="设备名称" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.deviceName" placeholder="请填写">
							<el-checkbox v-model="dataForm.showFlg" true-label="1" false-label="0"
								slot="append">报告显示</el-checkbox>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="技术协议要求厂家" class="item">
						<el-input class="width391" type="input" v-model="dataForm.technicalFactory" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="数量" class="item">
						<el-input class="width391" type="input" v-model="dataForm.quantity" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="合同签订情况" class="item">
						<el-select class="width391" v-model="dataForm.contractSignFlg" placeholder="请选择" clearable>
							<el-option v-for="item in contractSigns" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="实际到货厂家" class="item">
						<el-input class="width391" type="input" v-model="dataForm.deviceFactory" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="计划到货" class="item">
						<el-input class="width391" type="input" v-model="dataForm.arrivalDatePlan" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="实际到货" class="item">
						<el-input placeholder="请输入内容" class="width391" v-model="dataForm.arrivalDateFact"
							:disabled="dataForm.arrivalFlg === '0'">
							<el-checkbox v-model="dataForm.arrivalFlg" true-label="1" false-label="0"
								slot="prepend"></el-checkbox>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="产品证书" class="item">
						<el-input class="width391" type="input" v-model="dataForm.productCertifucate" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="备注" class="item">
						<el-input class="width391" type="input" v-model="dataForm.remark" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>

			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "purchaseDevice-add-or-update",
	data() {
		return {
			visible: true,
			form: {},
			dataForm: {
				workNo: $common.getItem("workNo"),
				deviceName: '',
				arrivalDateFact: '',
				arrivalDatePlan: "",
				arrivalFlg: '0',
				contractSignFlg: '0',
				deviceFactory: '',
				productCertifucate: '',
				quantity: "",
				remark: '',
				showFlg: '1',
				technicalFactory: ''
			},
			contractSigns: [{
				value: '0',
				label: '未签订'
			}, {
				value: '1',
				label: '已签订'
			}],
		};
	},
	components: {

	},
	// mounted() {
	// 	this.getSubjectDropDown();
	// },
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				deviceName: '',
				arrivalDateFact: '',
				arrivalDatePlan: "",
				arrivalFlg: '0',
				contractSignFlg: '0',
				deviceFactory: '',
				productCertifucate: '',
				quantity: "",
				remark: '',
				showFlg: '1',
				technicalFactory: ''
			}
			this.dataForm.recNo = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/purchasedeviceinfo/info/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
				}
			});
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/purchasedeviceinfo/save";
					if (this.dataForm.recNo && this.dataForm.recNo !== 0) {
						_url = "/business/purchasedeviceinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},

	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.width391 {
	width: 391px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}


.fondstyle {
	color: #3f649f;
	font-size: 15px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.item1::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
	border-left: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}
</style>
